.tile.feature.character {
  padding-top: 36px;
  padding-bottom: 32px;
  font-size: 32px;
  line-height: 0;
  transition: all 0.2s cubic-bezier(0.19, 1, 0.22, 1);
  text-align: center;
  transform: scale(1);
}

.tile.feature.character:hover {
  transform: scale(4.05);
  cursor: pointer;
  background: var(--black);
  color: var(--white);
  z-index: 1000;
}

.glyphset-container {
  grid-column-gap: 40px;
  grid-template-rows: auto;
  margin-bottom: 64px;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  display: grid;
}

.glyphset {
}

.specimen {
  width: 100%;
}
