.legal p {
  font-size: 24px !important;
  line-height: 120% !important;
  text-align: left !important;
  padding: 0 !important;
}

.legal {
  font-size: 24px !important;
  line-height: 120% !important;
  text-align: left !important;
  padding: 0 !important;
  margin-bottom: 40px;
}

.legal a {
  text-decoration: underline !important;
}

.legal h4,
.info-item {
  margin-top: 20px;
  margin-bottom: 8px;
}
