.features-grid {
  grid-row-gap: 16px;
  grid-column-gap: 16px;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  display: grid;
  align-content: normal;
  justify-content: normal;
  align-items: normal;
  justify-items: normal;
}

.features-grid .tile.feature {
  text-align: center;
  min-height: auto;
}

.features-grid.rare {
  grid-row-gap: 16px;
  grid-column-gap: 16px;
  grid-template-rows: auto auto;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr 1fr;
  display: grid;
  align-content: normal;
  justify-content: normal;
  align-items: normal;
  justify-items: normal;
  margin-top: 16px;
}
