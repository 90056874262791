:root {
  --white-smoke: #fafafa;
  --black: black;
}

.scroll-block {
  margin-top: -88px;
  padding-top: 88px;
}

.tile {
  background-color: var(--white-smoke);
  border-radius: 16px;
  overflow: hidden;
  position: relative;
}

.tile.dark {
  background-color: var(--black);
  flex-direction: column;
  justify-content: space-between;
  display: flex;
}

.tile.dark.cyrillic {
  background-color: var(--black);
  color: var(--white);
  padding: 2rem;
  width: 100%;
  overflow: hidden;
}

.tile.typetester {
  background-color: var(--white-smoke);
  min-height: auto;
  margin-bottom: 16px;
  padding: 34px 40px 24px;
  width: 100%;
}

.tile.typetester.dark {
  padding-top: 40px;
}

.tile.typetester.dark-background {
  background-color: var(--black);
}

.tile.typetester.mobile .cta-font-size {
  display: flex;
  align-items: center;
  gap: 8px;
}

.typetester-header {
  display: grid;
  grid-row-gap: 16px;
  grid-column-gap: 16px;
  grid-template-rows: auto;
  grid-template-columns: 0.25fr 0.25fr 1fr;
  grid-auto-columns: 1fr;
  align-items: center;
  margin-bottom: 16px;
  font-size: 16px;
  font-weight: 300;
  line-height: 100%;
}

.cta.current {
  background-color: var(--black);
  color: var(--white);
}

.typeplayground.ligature {
  font-variant-ligatures: discretionary-ligatures;
}

.typeplayground.alternate {
  font-feature-settings: "ss01";
}

.typeplayground.uppercase {
  text-transform: uppercase;
}

.slider {
  -webkit-appearance: none;
  width: 200px;
  height: 1px;
  background: #000;
  outline: none;
  position: relative;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 16px;
  height: 16px;
  border-radius: 16px;
  background: #000;
  cursor: grab;
}

.slider::-webkit-slider-thumb:active {
  cursor: grabbing;
}

.slider::-moz-range-thumb {
  width: 16px;
  height: 16px;
  border-radius: 16px;
  background: #000;
  cursor: grab;
}

.slider::-moz-range-thumb:active {
  cursor: grabbing;
}

.typeplayground.paragraph-small {
  font-size: 32px;
  line-height: 120%;
}

.tile.typetester.dark {
  padding-top: 40px;
}

.tile.typetester.dark-background {
  background-color: var(--black);
}

.tile.typetester.dark-background.big-center {
  color: var(--white);
  text-align: center;
  cursor: context-menu;
  padding-top: 140px;
  padding-bottom: 124px;
  padding-left: 40px;
  font-size: 148px;
  line-height: 94%;
}

.grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 16px;
  margin-bottom: 16px;
}

.pills {
  display: flex;
  justify-content: center;
  gap: 1rem;
  flex-wrap: wrap;
  position: absolute;
  left: 8px;
  top: 16px;
}

.pill {
  padding: 8px 16px;
  border-radius: 20px;
  transition: transform 0.5s ease;
}

.pill span {
  display: inline-block;
  padding: 4px 12px;
  border-radius: 16px;
}

.pill.rotate {
  transform: rotate(39.5772deg);
}

.pill.rotate._90 {
  transform: rotate(90deg);
}

.pill.rotate._135 {
  transform: rotate(135deg);
}

.tile.dark.cyrillic {
  padding-left: 0;
  padding-right: 0;
}

.tile.dark.cyrillic:hover .pill {
  transform: rotate(39.5772deg);
}

.font-light.cyrillic-preview {
  white-space: nowrap;
  margin-top: 8px;
  padding-left: 32px;
  padding-right: 32px;
  margin-bottom: 0px;
  font-size: 70px;
  font-weight: 400;
  line-height: 100%;
  cursor: grab;
  transition: transform 0.1s ease;
  overflow-x: auto;
  overflow-y: hidden;
  /* Hide scrollbar but keep functionality */
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  user-select: none; /* Empêche la sélection du texte */
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE/Edge */
}

.font-light.cyrillic-preview::-webkit-scrollbar {
  display: none; /* Chrome, Safari and Opera */
}
