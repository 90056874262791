:root {
  --black: black;
  --yellow: #dbff00;
  --white: white;
  --white-smoke: #fafafa;
  --silver: rgb(126, 126, 126);
}

:focus {
  outline: none;
}

#root {
  width: 100%;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-appearance: none;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: transparent;
}

html {
  scroll-behavior: smooth;
  background: var(--yellow);
}

body {
  color: var(--black);
  justify-content: center;
  padding-bottom: 64px;
  font-family: Uxum, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  display: flex;
  background-color: var(--white);
}

h1 {
  text-align: center;
  margin-top: 88px;
  margin-bottom: 24px;
  font-size: 158px;
  font-weight: 400;
  line-height: 92%;
  font-family: UxumVF, sans-serif;
}

h2 {
  margin-top: 48px;
  margin-bottom: 16px;
  font-size: 50px;
  font-weight: 400;
  line-height: 100%;
}

h3 {
  margin-top: 24px;
  margin-bottom: 10px;
  font-size: 28px;
  font-weight: 500;
  line-height: 40px;
}

p {
  text-align: center;
  margin-bottom: auto;
  padding-left: 130px;
  padding-right: 120px;
  font-size: 46px;
  line-height: 110%;
}

a {
  color: var(--black);
  text-decoration: none;
}

/* Container */
.container {
  max-width: 1216px;
  width: calc(100% - 48px);
  margin: 0 auto;
  padding: 0;
  position: relative;
  background-color: #fff;
}

.cta {
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  cursor: pointer;
  border: 1px dashed #000;
  border-radius: 32px;
  padding: 7px 10px 4px;
  font-family: Uxum, sans-serif;
  font-size: 16px;
  font-weight: 300;
  line-height: 100%;
  display: flex;
}

.cta:hover {
  background-color: var(--yellow);
}

.cta.active {
  border-color: var(--black);
}

.cta.primary {
  border-style: solid;
  border-color: #000;
  font-weight: 400;
}

.cta.primary:hover {
  background-color: var(--yellow);
}

.cta.primary.download {
  border-radius: 140px;
  margin-top: 16px;
  margin-bottom: 24px;
  padding: 24px 54px 16px;
  font-size: 86px;
}

#purchase .cta.primary.big {
  text-align: center;
  font-family: UxumVF, sans-serif;
  border-width: 4px;
  border-radius: 600px;
  margin-top: 24px;
  margin-bottom: 110px;
  padding-top: 45px;
  padding-bottom: 10px;
  font-size: 226px;
  font-weight: 400;
  display: block;
  background-color: var(--yellow);
  width: 100%;
  font-weight: 600;
}

.cta.active {
  background-color: var(--yellow);
  border-style: solid;
}

.cta.top.active {
  background-color: #2400ff;
  border-style: solid;
}

.cta.top {
  border-color: var(--white);
  color: var(--white);
}

.cta.top:hover {
  background-color: #2400ff;
}

.cta.bundle,
.cta.individual {
  justify-content: space-between;
}

.header {
  z-index: 1;
  color: #fff;
  mix-blend-mode: difference;
  justify-content: space-between;
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 24px;
  padding-bottom: 24px;
  display: flex;
  position: sticky;
  top: 0;
}

.logo {
  font-size: 32px;
  line-height: 100%;
  color: white;
}

.logo-light {
  font-weight: 200;
}

.logo-link {
  align-items: flex-end;
  display: flex;
}

.version {
  background-color: var(--white);
  color: var(--black);
  border-radius: 16px;
  margin-bottom: 5px;
  margin-left: 8px;
  padding: 6px 12px 4px;
  line-height: 100%;
  display: inline-block;
}

.grid {
  grid-template-rows: auto;
}
.navigation {
  grid-column-gap: 8px;
  align-items: center;
  display: flex;
}

.heading {
  margin-top: 96px;
  margin-left: 80px;
  font-family: Uxum Variable;
  font-weight: 400;
}

.width-light {
  font-weight: 300;
  display: block;
}

.width-regular {
  font-weight: 400;
  display: block;
}

.width-medium {
  font-weight: 500;
  display: block;
}

.width-bold {
  font-weight: 600;
}

.width-ultra {
  font-family: Uxum ultra;
  font-weight: 700;
  display: block;
}

.pill {
  background-color: var(--yellow);
  color: var(--black);
  border-radius: 16px;
  margin-bottom: 8px;
  margin-left: 8px;
  padding: 6px 16px 4px;
  line-height: 100%;
  transition: all 0.2s;
  display: inline-block;
  transform: rotate(0);
}

.pill.rotate {
  position: absolute;
  transform: rotate(45deg);
}

.pill.rotate._90 {
  transform: rotate(90deg);
}

.pill.rotate._135 {
  transform: rotate(135deg);
}

.typeplayground {
  font-size: 162px;
  line-height: 100%;
}

.typeplayground.ultralight {
  font-weight: 200;
}

.typeplayground.testerlight {
  font-weight: 300;
}

.typeplayground.regular {
  font-weight: 400;
}

.typeplayground.medium {
  font-weight: 500;
}

.typeplayground.bold {
  font-weight: 600;
}

.typeplayground.ultra {
  font-family: Uxum, sans-serif;
  font-weight: 900;
}

.typeplayground.paragraph-small {
  font-size: 32px;
  line-height: 120%;
}

.typeplayground.paragraph-small._50 {
  width: 50%;
  font-size: 20px;
}

.typetester-header {
  grid-template-rows: auto;
  grid-template-columns: 0.25fr 0.25fr 1fr;
  align-items: center;
  margin-bottom: 16px;
  font-size: 16px;
  font-weight: 300;
  line-height: 100%;
}

.ctas-typetester {
  display: flex;

  gap: 8px;
}

.features {
  margin-bottom: 16px;
}

.features.rare {
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr 1fr;
}

.grid-characters {
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  margin-top: 16px;
  display: grid;
}

.grid-characters._5 {
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
}

.grid-characters._8 {
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
}

.grid-characters._6 {
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
}

.ultra-light {
  font-weight: 200;
}

.light {
  font-weight: 300;
}

.medium {
  font-weight: 500;
}

.medium.left {
  text-align: left;
  padding-left: 0;
}

.bold {
  font-weight: 700;
}

.ultra {
  font-family: Uxum, sans-serif;
  font-weight: 900;
}

.hero {
  margin-bottom: 96px;
}

.clap:hover {
  position: relative;
  cursor: pointer;
}

.no-hover:hover::after {
  display: none;
}

.clap:hover::after {
  content: attr(data-hint);
  position: absolute;
  bottom: 50%;
  left: 50%;
  background-color: var(--black);
  color: white;
  padding: 5px 10px;
  border-radius: 400px;
  font-size: 24px;
  height: auto;
  display: block;
  white-space: nowrap;
  pointer-events: none;
  animation: wobble 0.5s ease-in-out infinite;
}

@keyframes wobble {
  0% {
    transform: rotate(-5deg) scale(0.7);
  }
  50% {
    transform: rotate(3deg) scale(0.6);
  }
  100% {
    transform: rotate(-5deg) scale(0.7);
  }
}

.label {
  color: var(--silver);
  letter-spacing: 1px;
  margin-top: 40px;
  font-size: 16px;
  font-weight: 400;
}

.label.small {
  color: var(--silver);
  letter-spacing: 0px;
  margin-top: 0px;
  margin-bottom: 4px;
  font-size: 16px;
  font-weight: 400;
}

.scroll-block {
  margin-top: -88px;
  padding-top: 88px;
}

.scroll-block.bag {
  grid-column-gap: 4px;
  grid-row-gap: 4px;
  flex-direction: column;
  display: flex;
}

.items {
  grid-column-gap: 24px;
  justify-content: center;
  margin-bottom: 24px;
  font-size: 180px;
  line-height: 100%;
  display: flex;
}

.items.down {
  flex-direction: column;
  margin-top: 24px;
  margin-bottom: 40px;
  font-size: 150px;
  line-height: 110%;
}

.text-block {
  color: var(--silver);
}

.arrow {
  color: var(--silver);
  font-size: 120px;
}

.grey {
  color: var(--silver);
}

.left {
  text-align: left;
  padding-left: 0;
}

.specimen {
  margin-bottom: 8px;
}

.cta-font-size {
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  border: 1px dashed #0000;
  border-radius: 32px;
  padding: 7px 8px 4px;
  font-family: Uxum, sans-serif;
  font-size: 16px;
  font-weight: 300;
  line-height: 100%;
  display: flex;
}

.cta-font-size:hover {
  background-color: var(--yellow);
  border-style: dotted;
  border-color: #0000;
}

.cta-font-size.primary {
  border-style: solid;
  border-color: #000;
  font-weight: 400;
}

.cta-font-size.primary:hover {
  background-color: var(--yellow);
}

.cta-font-size.primary.download {
  border-radius: 140px;
  margin-top: 16px;
  margin-bottom: 24px;
  padding: 24px 54px 16px;
  font-size: 86px;
}

.cta-font-size.active {
  background-color: var(--yellow);
  border-style: solid;
}

.discount {
  text-decoration: line-through;
  color: var(--silver) !important;
}

.summary {
  font-size: 70px;
  line-height: 70px;
}

.utility-page-wrap {
  justify-content: center;
  align-items: center;
  width: 100vw;
  max-width: 100%;
  height: 100vh;
  max-height: 100%;
  display: flex;
}

.utility-page-content {
  text-align: center;
  flex-direction: column;
  width: 260px;
  display: flex;
}

@media screen and (max-width: 1000px) {
  .logo {
    font-size: 26px;
  }

  .logo-link {
    align-items: center;
  }

  .version {
    font-size: 16px;
  }

  body {
    padding-left: 16px;
    padding-right: 16px;
  }

  .container {
    width: 100%;
  }

  .header {
    padding: 16px 0;
  }

  .main {
    width: 100%;
  }

  .cta.top {
    display: none;
  }

  .cta.primary {
    display: block;
  }

  .cta.primary.big {
    font-size: 18vw;
  }

  .grid {
    grid-template-rows: auto auto auto;
    grid-template-columns: 1fr;
  }

  .features,
  .glyphset {
    grid-template-columns: 1fr;
  }

  h1 {
    font-size: 56px;
  }

  h2 {
    font-size: 40px;
  }

  h3 {
    font-size: 24px;
    line-height: 120%;
  }

  p {
    padding-left: 0;
    padding-right: 0;
  }
  .tile.feature.character:hover {
    transform: scale(2.05) !important;
  }
  .font-light.cyrillic-preview {
    font-size: 50px !important;
    padding-left: 32px !important;
    padding-right: 32px !important;
  }

  .tile.dark,
  .tile.dark.cyrillic {
    overflow: scroll;
  }

  .tile.dark,
  .tile.dark.cyrillic .pill {
    display: none;
  }

  #hero {
    margin-bottom: 48px;
  }

  #hero p {
    font-size: 20px;
  }

  .cta.primary.download {
    text-align: center;
    font-size: 30px;
  }

  .glyphset-container {
    grid-template-columns: 1fr !important;
    grid-auto-columns: 1fr !important;
  }

  .cta.primary.big {
    padding-top: 18px;
  }

  .tile.typetester {
    padding: 16px !important;
    width: 100% !important;
  }

  .tile.feature.character {
    padding-top: 24px;
    padding-bottom: 24px;
    font-size: 20px;
  }

  .typeplayground {
    font-size: 48px;
  }

  .typetester-header {
    display: grid !important;
    grid-template-areas:
      "title weight"
      "controls controls" !important;
    grid-template-columns: auto 1fr !important;
    gap: 8px !important;
    margin-bottom: 16px !important;
    font-size: 16px !important;
    font-weight: 300 !important;
    line-height: 100% !important;
  }

  .tile.typetester.dark-background.big-center {
    font-size: 14vw !important;
    padding: 7vw 0 !important;
  }

  .typetester-header > *:nth-child(2) {
    grid-area: weight !important;
    justify-self: end !important;
  }

  .ctas-typetester {
    grid-area: controls !important;
    display: grid !important;
    grid-template-columns: repeat(3, 1fr) !important;
    gap: 8px !important;
    width: 100% !important;
  }

  .slider {
    width: 100% !important;
  }

  .ctas-typetester > * {
    width: 100%;
  }

  .ctas-typetester > *:nth-child(n + 4) {
    grid-column: 1 / -1;
  }

  .ctas-typetester .cta {
    font-size: 14px !important;
    justify-content: center !important;
  }

  .features.rare {
    grid-template-columns: 1fr;
  }

  .label {
    margin-top: 32px;
    margin-bottom: 8px;
  }

  .items {
    grid-column-gap: 16px;
    font-size: 80px;
  }

  .items.down {
    margin-top: 8px;
    margin-bottom: 24px;
    font-size: 38px;
  }

  .arrow {
    font-size: 57px;
  }

  .glyphset {
    grid-template-rows: auto auto;
  }

  .features-grid,
  .features-grid.rare {
    display: flex !important;
    flex-direction: column !important;
  }

  .grid-characters,
  .grid-characters._6 {
    width: 100% !important;
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }

  .cta-font-size {
    display: none;
    transform: translate(0);
  }

  #purchase .summary {
    font-size: 40px;
  }

  #purchase .cta.primary.big {
    font-size: 16vw;
    line-height: 100%;
    padding: 24px 0 16px;
  }
}
/* Font faces */
@font-face {
  font-family: "Uxum";
  src: url("../fonts/Uxum-UltraLight.woff2") format("woff2");
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Uxum";
  src: url("../fonts/Uxum-Light.woff2") format("woff2");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Uxum";
  src: url("../fonts/Uxum-Regular.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Uxum";
  src: url("../fonts/Uxum-Medium.woff2") format("woff2");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Uxum";
  src: url("../fonts/Uxum-Bold.woff2") format("woff2");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Uxum";
  src: url("../fonts/Uxum-Ultra.woff2") format("woff2");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "UxumVF";
  src: url("../fonts/UxumVF.woff2") format("woff2-variations");
  font-weight: 200 900;
  font-style: normal;
  font-display: swap;
}

/* Classes utilitaires pour les différents poids de police */
.ultra-light {
  font-weight: 200;
}

.light {
  font-weight: 300;
}

.regular {
  font-weight: 400;
}

.medium {
  font-weight: 500;
}

.bold {
  font-weight: 700;
}

.ultra {
  font-weight: 900;
}

/* Vous pouvez continuer à ajouter d'autres styles globaux ici */

::selection {
  color: #dbff00;
  background: transparent;
}

h1 .breathe {
  font-variation-settings: "wght" 100;
  font-family: UxumVF, sans-serif;
  animation: breathe 4000ms infinite both;
}

@keyframes breathe {
  0% {
    font-variation-settings: "wght" 600;
  }
  25% {
    font-variation-settings: "wght" 900;
  }
  75% {
    font-variation-settings: "wght" 200;
  }
  100% {
    font-variation-settings: "wght" 600;
  }
}

.alt {
  font-feature-settings: "ss01";
}

.lig {
  font-variant-ligatures: discretionary-ligatures;
}

.footer p {
  font-size: 14px;
  text-align: left;
  padding: 0;
}

#purchase .cta {
  font-size: 24px;
  padding: 13px 16px 10px;
}

#purchase .cta.primary.breathe {
  font-family: UxumVF, sans-serif;
  animation: breathe 4000ms infinite both;
}

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

.loading-spinner {
  animation: pulse 1.5s ease-in-out infinite;
  font-size: 1.2rem;
  color: #333;
}

@keyframes pulse {
  0% {
    opacity: 0.5;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.5;
  }
}

@keyframes hideHover {
  0%,
  100% {
    pointer-events: none;
    opacity: 0;
  }
}

.clap:hover::after {
  content: attr(data-hint);
  position: absolute;
  bottom: 50%;
  left: 0%;
  background-color: var(--black);
  color: white;
  padding: 5px 40px;
  border-radius: 400px;
  font-size: 24px;
  height: auto;
  display: block;
  white-space: nowrap;
  pointer-events: none;
  animation: wobble 1s ease-in-out infinite;
  font-family: Uxum, sans-serif;
  transform-origin: center;
}

.clap.no-hover:hover::after {
  animation: hideHover 2s forwards;
}

.clap:is(.listening):hover::after {
  animation: fadeIn 0.3s ease-in-out;
}

.no-tooltip:before,
.no-tooltip:after {
  display: none !important;
}

.clap:hover {
  animation: vibrate 3.5s ease-in-out infinite;
}
.clap.listening:hover {
  animation: none;
}

@keyframes vibrate {
  0% {
    font-variation-settings: "wght" 400;
  }
  10% {
    font-variation-settings: "wght" 900;
  }
  20% {
    font-variation-settings: "wght" 200;
  }
  30% {
    font-variation-settings: "wght" 800;
  }
  40% {
    font-variation-settings: "wght" 300;
  }
  50% {
    font-variation-settings: "wght" 700;
  }
  60% {
    font-variation-settings: "wght" 400;
  }
  70% {
    font-variation-settings: "wght" 600;
  }
  80% {
    font-variation-settings: "wght" 400;
  }
  90% {
    font-variation-settings: "wght" 500;
  }
  100% {
    font-variation-settings: "wght" 400;
  }
}

.not-found {
  position: relative;
}

.cursor-blur {
  transition: all 0.1s ease;
}

.not-found-container {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.content {
  /* Styles pour le contenu */
}

.cursor-circle {
  position: fixed;
  transform: translate(-50%, -50%);
  pointer-events: none;
  /* Ajoutez vos styles ici pour le cercle */
}

@keyframes rotate {
  from {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  to {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

.cta.loading {
  opacity: 0.7;
  cursor: wait;
  position: relative;
}

.cta.loading::after {
  content: "";
  position: absolute;
  width: 16px;
  height: 16px;
  top: 50%;
  right: 16px;
  margin-top: -8px;
  border: 2px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top-color: #fff;
  animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.slider {
  cursor: pointer;
}
